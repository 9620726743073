import React from 'react';
import logo from './wahping.png';
import './App.css';
import {
  createUseStyles,
  ThemeProvider,
  useTheme
} from "react-jss";
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import {
  debounce,
  isEmpty,
  set,
  every,
  some
} from "lodash-es"
import * as style from './style'
import { AiOutlineCloseSquare } from 'react-icons/ai'
import {
  Form
} from 'react-bootstrap'

const endpoint = 'https://hvfk54zmsq3whdzn2sp36fkvhu0xrhyy.lambda-url.us-east-1.on.aws/'

const {
  Group,
  Label,
  Control
} = Form

const _ = {
  debounce,
  isEmpty,
  set,
  every,
  some
}

const useStyles = createUseStyles(style);

const { useState, useEffect } = React

const validationRegex = {
  name: new RegExp(/^[a-zA-Z][a-zA-Z\s]{0,20}[a-zA-Z]$/),
  email: new RegExp(/^[a-zA-Z0-9_!#$%&‘*=+/?^{|}~]+([.-]?[a-zA-Z0-9_!#$%&‘*=+/?^{|}~]+)*@\w+([.-]?\w+)*(\.\w{2,50})+$/)
}

// interface IBlockProps {
//   backgroundImg: string
//   link: string
//   text: string
// }

// interface HamburgerProps {
//   open: boolean
//   setOpen: (e: boolean) => void
// }

// type navLink = {
//   id: string
//   text: string
//   link: string
// }

// const navigationLinks: navLink[] = [
//   {
//     id: 'home',
//     text: 'Home',
//     link: '/'
//   },
//   // {
//   //   text: "Login",
//   //   link: '/login'
//   // },
//   {
//     id: 'contact-us',
//     text: 'Contact Us',
//     link: '/contact-us'
//   },
//   {
//     id: 'privacy-policy',
//     text: 'Privacy Policy',
//     link: '/privacy-policy'
//   }
// ]

const theme = {
  background: "#f7df1e",
  color: "#24292e"
};



// function funcNav(href: string): void {
//   let el = document.createElement('a')
//   el.href = href
//   document.body.append(el)
//   el.click()
// }


// function Hamburger(props: HamburgerProps) {
//   const classes = useStyles({ theme });

//   const {
//     open,
//     setOpen
//   } = props

//   const handleClick = () => {
//     setOpen(!open)
//   }

//   const pattyClasses = [classes.patty, open ? 'open' : undefined].filter(e => e).join(' ')

//   return (
//     <div className={[classes.hamburgerContainer, open ? 'open' : undefined].filter(e => !!e).join(' ')}>
//       <button
//         className={classes.hamburger}
//         onClick={() => handleClick()}
//       >
//         <div className={[pattyClasses, classes['patty-1']].join(' ')}></div>
//         <div className={[pattyClasses, classes['patty-2']].join(' ')}></div>
//         <div className={[pattyClasses, classes['patty-3']].join(' ')}></div>
//         <div className={[pattyClasses, classes['patty-4']].join(' ')}></div>
//       </button>
//     </div>
//   )
// }

function App() {
  const [showModal, setShowModal] = useState(false)
  const [bodyHeight, setBodyHeight] = useState('500px')

  // let navigate = useNavigate()
  useEffect(
    () => {
      const newHeight = window.innerHeight * 0.8
      setBodyHeight(`${newHeight > 500 ? newHeight : 500}px`)
    },
    []
  );

  const classes = useStyles({ theme })

  return (
    <ThemeProvider theme={theme}>
      <div className={`App`} >
        <header className="App-header">
          <img src={logo} className="App-logo" alt="Wahping LLC Logo" />
          {/* <Hamburger
            open={menuOpen}
            setOpen={setMenuOpen}
          /> */}
        </header>
        {/* <ul className={[classes.menu, menuOpen ? 'open' : undefined].filter(e => e).join(' ')}>
          {navigationLinks.map(e => {
            return (
              <li key={e.id} className={classes.menuItem}>
                <Link
                  to={`${e.link}`}
                  className={classes.navLink}
                  onClick={() => setMenuOpen(false)}
                >
                  {`${e.text}`}
                </Link>
              </li>)
          })}
        </ul> */}
        <div className="App-body" style={{ height: bodyHeight }}>
          {/* <div className="first section">
            <h1 className={classes['main-slogan']}>Digital Solutions for Everyone</h1>
            <div className="content-container">
              
              <span>
                to talk about your customized web application today.
              </span>
            </div>
          </div> */}
          <div className={`${classes.section} ${classes.secondBgImg}`}>
            {/* <img src={secondBg} className={classes.secondBgImg} /> */}
            <h1>Support for you and your business wherever you are.</h1>
            <button
              className={classes['btn-main']}
              onClick={() => setShowModal(true)}>
              Contact Us
            </button>

          </div>
        </div>
        <footer className="footer">
          <span>&copy; {new Date().getFullYear()} Wahping, LLC</span>
        </footer>
      </div>
      <MessageModal
        show={showModal}
        setShow={setShowModal}
      />
    </ThemeProvider>
  );
}

function RouterWrapper() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
      </Routes>
    </BrowserRouter>
  )
}

// type MessageFormData = {
//   name: string
//   email: string
//   comments: string
// }

enum VALIDATION_ERRORS {
  NAME_REQ = `Name is required.`,
  NAME_REGEX = `Name must be valid.`,
  EMAIL_REQ = `Email is required.`,
  EMAIL_REGEX = `Email must be valid.`,
  COMMENTS_REQ = `Comments are required.`
}

function MessageModal(props: any) {
  const { show, setShow } = props

  // let navigate = useNavigate()

  const theme: any = useTheme();
  const classes = useStyles({ theme });

  const closeModal = () => {
    setShow(false)
  }

  if (!show) {
    return null
  }

  return (
    <div
      className={`modal-background show`}
      id="modal-background"
      onClick={(e) => {
        if ((e.target as any)?.id === 'modal-background') {
          closeModal()
        }
      }}
    >
      <div
        className={classes.modal}
        onClick={(e) => e.preventDefault()}
        id="modal-content"
      >
        <button
          className={classes.xOut}
          onClick={() => closeModal()}
        >
          <AiOutlineCloseSquare />
        </button>

        <ContactForm
          closeModal={closeModal}
        />
      </div>
    </div>
  )
}

function ContactForm(props: any) {

  const theme: any = useTheme();
  const classes = useStyles({ theme });

  let errorSchemaTemplate = {
    name: {
      hasError: false,
      error: ''
    },
    email: {
      hasError: false,
      error: ''
    },
    comments: {
      hasError: false,
      error: ''
    }
  }

  let [formData, setFormData] = useState({
    name: '',
    email: '',
    comments: ''
  })
  let [errorSchema, setErrorSchema] = useState({
    ...errorSchemaTemplate
  })
  let [submitted, setSubmitted] = useState(false)

  const cancelForm = (): void => {
    setFormData({
      name: '',
      email: '',
      comments: ''
    })
    setSubmitted(false)
    props.closeModal()
  }

  const submitForm = (): any => {
    let xhr = new XMLHttpRequest()
    xhr.open("POST", endpoint)
    xhr.setRequestHeader("Accept", "application/json");
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.send(JSON.stringify(formData))
    setSubmitted(true)
  }

  const validate = (formKey: string, formData: string): any => {

    let errors = {}

    switch (formKey) {
      case 'name':
        _.set(errors, 'name.hasError', false)
        _.set(errors, 'name.error', '')
        if (_.isEmpty(formData)) {
          _.set(errors, 'name.hasError', true)
          _.set(errors, 'name.error', VALIDATION_ERRORS.NAME_REQ)
        }
        else {
          _.set(errors, 'name.hasError', !validationRegex.name.test(formData))
          _.set(errors, 'name.error', VALIDATION_ERRORS.NAME_REGEX)
        }
        break
      case 'email':
        _.set(errors, 'email.hasError', false)
        _.set(errors, 'email.error', '')
        if (_.isEmpty(formData)) {
          _.set(errors, 'email.hasError', true)
          _.set(errors, 'email.error', VALIDATION_ERRORS.EMAIL_REQ)
        } else {
          _.set(errors, 'email.hasError', !validationRegex.email.test(formData))
          _.set(errors, 'email.error', VALIDATION_ERRORS.EMAIL_REGEX)
        }
        break
      case 'comments':
        _.set(errors, 'comments.hasError', false)
        _.set(errors, 'comments.error', '')
        if (_.isEmpty(formData)) {
          _.set(errors, 'comments.hasError', true)
          _.set(errors, 'comments.error', VALIDATION_ERRORS.COMMENTS_REQ)
        }
    }

    setErrorSchema({ ...errorSchema, ...errors })
  }

  const debouncedValidate = _.debounce(function (target) { validate(target.name, target.value) }, 500)

  const handleOnChange = (e: any) => {
    const { target } = e

    debouncedValidate(target)
    setFormData({ ...formData, [target.name]: target.value })
  }

  if (!submitted) {
    return <Form>
      <p style={{ marginTop: 0, textAlign: 'center' }}>
        Please fill out the entire form below or email us at <a href="mailto:info@wahping.com">info@wahping.com</a> for any inquiries.
      </p>
      <Group className={classes.formGroup} controlId="formName">
        <Label>Name</Label>
        <Control
          name="name"
          onChange={
            (e) => handleOnChange(e)
          }
          type={'text'}
          value={formData.name} />
        {errorSchema.name.hasError &&
          <Control.Feedback type="invalid">
            {errorSchema.name.error}
          </Control.Feedback>}
      </Group>
      <Group className={classes.formGroup} controlId="formEmail">
        <Label>Email</Label>
        <Control
          name="email"
          onChange={
            (e) => handleOnChange(e)
          }
          type={'email'}
          required={true}
          isInvalid={errorSchema.email.hasError}
          value={formData.email} />
        {errorSchema.email.hasError &&
          <Control.Feedback type="invalid">
            {errorSchema.email.error}
          </Control.Feedback>}
      </Group>
      <Group className={classes.formGroup} controlId="formComments">
        <Label style={{ marginBottom: '3px' }}>Comments</Label>
        <Control as="textarea"
          name="comments"
          className={classes.textarea}
          onChange={
            (e) => handleOnChange(e)
          }
          required={true}
          isInvalid={errorSchema.comments.hasError}
          value={formData.comments} />
        {errorSchema.comments.hasError &&
          <Control.Feedback type="invalid">
            {errorSchema.comments.error}
          </Control.Feedback>}
      </Group>
      <div className={classes.formButtons}>
        <button
          className={classes.modalButton}
          onClick={() => cancelForm()}
        >
          Cancel
        </button>
        <button
          className={classes.modalButton}
          type="submit"
          disabled={!(!_.some(errorSchema, 'hasError') && _.every(formData))}
          onClick={() => submitForm()}
        >
          Submit
        </button>
      </div>
    </Form>
  } else {
    return <>
      <h2>Thank you.</h2>
      <p>We will be in touch with you shortly!</p>
      <div className={classes.formButtons}>
        <button
          className={classes.modalButton}
          onClick={() => cancelForm()}
        >
          Okay
        </button>
      </div>
    </>
  }
}

export default RouterWrapper;
