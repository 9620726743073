//const { withTheme } = require("react-jss");

module.exports = {
  wrapper: {
    padding: 40,
    background: ({ theme }) => theme.background,
    textAlign: "left"
  },
  title: {
    font: {
      size: 40,
      weight: 900
    },
    color: ({ theme }) => theme.color
  },
  link: {
    color: ({ theme }) => theme.color,
    "&:hover": {
      opacity: 0.5
    }
  },
  section: {
    height: '100%',
    minHeight: '500px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1.5rem',
    padding: '0 5px',
    '&:before': {
      content: '',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
      zIndex: 0
    },
    "& > *": {
      position: 'relative',
      zIndex: 1
    }
  },
  secondBgImg: {
    background: 'linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.8) ),url(imgs/hands-using-laptop.jpg) center no-repeat',
    backgroundSize: 'cover'

  },
  "modal-background": {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,0.8)',
    zIndex: 2,
    display: 'none',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modal: {
    width: '400px',
    zIndex: 5,
    backgroundColor: 'white',
    padding: '25px',
    position: 'relative'
  },
  modalButton: {
    border: "2px solid black",
    background: "transparent",
    transition: "background 0.2s ease",
    "&:hover": {
      background: "rgba(150,150,150,0.2)"
    }
  },
  textarea: {
    resize: 'none',
    border: "solid black 2px",
    height: "200px"
  },
  navBtn: {

  },
  menu: {
    color: 'white',
    position: 'fixed',
    top: 0,
    right: 0,
    zIndex: '-100',
    width: '40%',
    //height: '30vh',
    opacity: 0,
    background: 'black',
    margin: 0,
    padding: '25px 50px',
    fontSize: '1.5rem',
    transform: 'translate(10px)',
    '&.open': {
      transition: '0.5s',
      transitionProperty: 'opacity, transform',
      zIndex: 10,
      opacity: 1,
      transform: 'translate(0)'
    }
  },
  menuItem: {
    listStyleType: 'none',
    marginBottom: '15px',
    textAlign: 'center',
    '&:last-child': {
      margin: 0
    }
  },
  navLink: {
    color: 'white',
    textDecoration: 'none',
    lineHeight: 0.3,
    display: 'inline-block',
    '&:after': {
      content: '""',
      height: '1px',
      width: 0,
      backgroundColor: 'white',
      transition: '0.5s ease width',
      display: 'inline-block'
    },
    '&:hover': {
      '&:after': {
        width: '100%'
      }
    }
  },
  hamburgerContainer: {
    width: "50px",
    height: "50px",
    //border: "solid white 1px",
    //borderRadius: "5px",
    position: "fixed",
    top: "5px",
    right: "5px",
    zIndex: 20,
    transition: "0.5s"
  },
  hamburger: {
    border: 'none',
    float: 'right',
    width: "50px",
    height: "50px",
    //display: "flex",
    //flexDirection: "column",
    //flexFlow: "column",
    //justifyContent: "space-evenly",
    background: 'transparent',
    position: 'relative',
    transition: '0.5s ease all',
    "&:hover": {
      cursor: "pointer"
    }
  },
  patty: {
    height: "5px",
    width: "30px",
    background: 'black',
    border: '1px solid white',
    //alignSelf: "center",
    borderRadius: "25px",
    position: 'absolute',
    left: '10px',
    transition: '0.5s ease all',
    opacity: 1,
  },
  'patty-1': {
    transform: 'translate(0, -10px)',
    '&.open': {
      transform: 'rotate(45deg)',
      backgroundColor: 'white',
      border: 'none'
    }
  },
  'patty-2': {
    '&.open': {
      transform: 'rotate(-45deg) translate(0px, 1px)',
      border: 'none',
      zIndex: 11,
      backgroundColor: 'white'
    }
  },
  'patty-3': {
    opacity: 0,
    '&.open': {
      transform: 'rotate(45deg) translate(1px, 0px)',
      border: 'none',
      zIndex: 11,
      opacity: 1,
      backgroundColor: 'white'
    }
  },
  'patty-4': {
    transform: 'translate(0, 10px)',
    '&.open': {
      transform: 'rotate(-45deg)',
      backgroundColor: 'white',
      border: 'none'
    }
  },
  "main-slogan": {
    fontSize: "2.5rem",
    margin: "auto 10px"
  },
  "btn-main": {
    background: 'transparent',
    color: 'white',
    border: 'solid white 7px',
    borderRadius: '25px',
    fontSize: '1.8rem',
    fontWeight: 600,
    marginRight: '5px',
    transition: 'color 0.25s, border-color 0.25s',
    padding: '5px 10px',
    width: '200px',

    "&:hover": {
      color: 'rgb(220, 220, 220)',
      borderColor: 'rgb(220, 220, 220)',
      cursor: 'pointer'
    },
    "&:active": {
      color: 'rgb(100, 100, 100)',
      borderColor: 'rgb(120, 120, 120)',
      transform: 'translate(1px, 1px)'
    }
  },
  formGroup: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '10px',
    "& input": {
      border: 'none',
      borderBottom: 'solid black 2px',
      height: '25px',
      fontSize: '1.2rem'
    },
    "& textarea": {
      border: 'solid black 2px',
      height: '200px',
      fontSize: '1rem'
    }
  },
  formButtons: {
    display: 'flex',
    justifyContent: 'end',
    "& button": {
      marginLeft: '15px',
      fontSize: '1.2rem',
      padding: '5px 10px'

    }
  },
  'xOut': {
    position: 'absolute',
    top: '5px',
    right: '5px',
    fontSize: '2rem',
    border: 'none',
    background: 'transparent',
    fontWeight: 800,
    padding: 0,
    margin: 0,
    width: '32px',
    height: '32px',
    '&:hover': {
      backgroundColor: 'rgba(220, 220, 220)'
    },
    '&:active': {
      transform: 'translate(1px, 1px)'
    }
  }
}
